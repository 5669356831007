<template>

  <v-container>
    <v-row>
      <!-- <v-col cols="12" class="d-flex justify-space-between mb-6">
        <h3>首页</h3>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
      </v-col> -->
      <v-col cols="12">
        <v-card>
          <v-card-title>
            用户列表
            <v-spacer></v-spacer>
            <!-- <v-breadcrumbs :items="items"></v-breadcrumbs> -->
          </v-card-title>
          <v-card-subtitle>
            <v-form>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field v-model="form.uname" label="用户名" hide-details dense clearable></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="form.email" label="邮箱" hide-details dense clearable></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="form.phone" label="手机" hide-details dense clearable></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn class="mx-2" fab dark x-small color="primary" @click="getUserList">
                    <v-icon x-small dark>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

          </v-card-subtitle>
          <v-card-text>
            <v-data-table :headers="headers" disable-sort :items="userList" :loading="loading" hide-default-footer :server-items-length="form.limit" class="elevation-1">
              <template v-slot:item.roles="{ item }">
                <v-chip v-if="item.roles=='admin'" small color="red" dark>管理</v-chip>
                <v-chip v-if="item.roles=='member'" small color="orange" dark>用户</v-chip>
              </template>
              <template v-slot:item.enabled="{ item }">
                <span :style="{color:item.enabled==1?'green':'red'}">{{item.enabled==1?'启用':'禁止'}}</span>
              </template>
              <template v-slot:item.option="{ item }">
                <v-btn class="mx-2" fab dark x-small color="cyan" @click="editBrand(item)">
                  <v-icon x-small dark>mdi-pencil</v-icon>
                </v-btn>
                <!-- <v-btn class="mx-2" fab dark x-small color="pink" @click="deleteBrand(item)">
                  <v-icon x-small dark>mdi-delete</v-icon>
                </v-btn> -->
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" md="6" xs="12">
                <v-pagination v-model="currentPage" :length="pageCount" total-visible="5" @input="getUserList"></v-pagination>
              </v-col>
              <v-col cols="12" md="6" xs="12">
                <v-select :items="limits" v-model="form.limit" label="条数" dense @change="limitChange"></v-select>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">编辑用户</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="editForm.password" label="密码"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-select :items="roleList" v-model="editForm.roles" label="角色" dense></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select :items="statusList" v-model="editForm.enabled" label="状态" dense></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">取消</v-btn>
            <v-btn color="blue darken-1" text @click="save">确定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>

</template>

<script>
  // @ is an alias to /src
  import { post, tips, limits } from '@/facade'
  export default {
    name: 'home',
    data() {
      return {
        items: [
          { text: '首页', disabled: false, href: '/home', },
          // { text: 'Link 1', disabled: false, href: 'breadcrumbs_link_1', },
          // { text: 'Link 2', disabled: true, href: 'breadcrumbs_link_2', },
        ],
        loading: true,
        form: {
          limit: 20,
          offset: 0,
          desc: true
        },
        currentPage: 1,
        pageCount: 1,
        limits: limits,
        headers: [
          { text: '邮箱', value: 'email' },
          { text: '角色', value: 'roles' },
          { text: '是否禁用', value: 'enabled' },
          { text: '登陆IP', value: 'last_ip' },
          { text: '操作', value: 'option' },
        ],
        dialog: false,
        editForm: {},
        roleList: [
          { text: '管理员', value: 'admin' },
          { text: '用户', value: 'member' },
        ],
        statusList: [
          { text: '开启', value: true },
          { text: '禁止', value: false },
        ],
        userList: [],
      }
    },
    async created() {
      await this.getUserList()
    },


    methods: {
      /**每页显示条数变化 */
      async limitChange() {
        this.currentPage = 1;
        this.getUserList();
      },
      // 获取用户列表
      async getUserList() {
        for (let item in this.form) {
          if (!this.form[item]) {
            delete this.form[item]
          }
        }
        this.form.offset = this.form.limit * (this.currentPage - 1);
        const data = await post('/user/list', this.form)
        if (data.code == 'ok') {
          this.loading = false;
          this.userList = data.users;
          this.pageCount = data.count > 0 ? Math.ceil(data.count / this.form.limit) : 1;
        } else {
          tips('error', data.message)
        }
      },
      /**编辑 */
      editBrand(item) {
        this.dialog = true;
        this.editForm.uid = item.id;
        this.editForm.roles = item.roles[0];
        this.editForm.enabled = item.enabled == 1 ? true : false
      },
      /**保存*/
      async save() {
        const form = {
          uid: this.editForm.uid,
          roles: [this.editForm.roles],
          enabled: this.editForm.enabled,
          password: this.editForm.password
        }
        const data = await post('/user/update', form);
        if (data.code == 'ok') {
          tips('success', '修改成功')
          this.dialog = false;
          this.getUserList()
        } else {
          tips('error', data.message)
        }
      }

    },
  }
</script>